<template>
  <q-page class="flex flex-center">
    Chargement...
  </q-page>
</template>

<style>
</style>

<script>
export default {
  name: 'PageHome',
  created() {
    if (this.$can('read', 'airquality')) {
      this.$router.replace({name: 'airquality'});
    } else if (this.$can('read', 'airquality-outdoor')) {
      this.$router.replace({name: 'airquality-outdoor'});
    } else if (this.$can('read', 'airquality-mobility')) {
      this.$router.replace({name: 'airquality-mobility'});
    }
  },
};
</script>
